<template>
  <div v-if="isNull(label)">
    <s v-if="isBefore">{{ before }}</s>
    <span :class="isBefore ? afterClass : ''">{{ after }}</span>
  </div>
  <b-form-group
      :label-cols="labelCol"
      :label-cols-lg="labelCol"
      :label="label"
      label-size="sm"
      class="mb-1"
      v-else
  >
    <div v-if="convertType === 'file'">
      <s v-if="isBefore">
        <attachment-upload :id="after" theme="files" :readonly="true">
        </attachment-upload>
      </s>
      <attachment-upload :id="after" :class="isBefore ? afterClass : ''" theme="files" :readonly="true">

      </attachment-upload>
    </div>

    <div v-else>
      <s v-if="isBefore">{{ before }}</s>
      <span :class="isBefore ? afterClass : ''">{{ after }}</span>
    </div>
  </b-form-group>

</template>

<script>
import { getCodeLabel, toDate } from '@core/utils/filter'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'

export default {
  name: 'change-label',
  components: { AttachmentUpload },
  props: {
    label: {
      type: String,
    },
    changeData: {
      default: ''
    },
    convertType: {
      type: String,
    },
    labelCol: {
      type: Number,
      default: 3
    }
  },
  data: () => ({
    afterClass: 'text-danger'
  }),
  computed: {
    before() {
      return this.getLabel(this.changeData['before'])
    },
    after() {
      if (this.changeData['after'] === undefined) {
        return this.getLabel(this.changeData)
      } else {
        return this.getLabel(this.changeData['after'])
      }
    },
    isBefore(){
      return !this.isNull(this.before)
    },
  },
  watch: {},
  methods: {
    getLabel(value) {
      if (this.convertType === 'date') {
        return value ? toDate(value) : value
      } else if (this.convertType === 'file') {
        return value
      } else {
        return this.convertType ? this.codeLabel(value) : value
      }
    },
    codeLabel(value) {
      if (typeof (value) === 'string' && value.indexOf(',') !== -1) {
        return value.split(',')
            .map(item => getCodeLabel(this.convertType, item))
            .join(',')
      }
      return getCodeLabel(this.convertType, value)
    },

    isNull(value){
      return value === undefined || value === '';
    }
  },
  created() {

  }
}
</script>

<style scoped>

</style>
